@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

@import "./telq";

// import functions and variables for custom theme color
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$custom-font-sizes: (
  "H20": $H20,
  "H16": $H16,
  "H14": $H14,
  "H13": $H13,
  "H12": $H12,
  "H11": $H11
);

// custom theme color according to the figma design
$custom-theme-colors: (
  "primary-p1": $primary-p1,
  "primary-p2": $primary-p2,
  "primary-p3": $primary-p3,
  "primary-p4": $primary-p4,
  "primary-p8": $primary-p8,
  "primary-p9": $primary-p9,
  "primary-p10": $primary-p10,
  "primary-p11": $primary-p11,
  "neutral-n1": $neutral-n1,
  "neutral-n2": $neutral-n2,
  "neutral-n3": $neutral-n3,
  "neutral-n4": $neutral-n4,
  "neutral-n5": $neutral-n5,
  "neutral-n6": $neutral-n6,
  "neutral-n7": $neutral-n7,
  "neutral-n8": $neutral-n8,
  "neutral-n9": $neutral-n9,
  "neutral-n10": $neutral-n10,
  "accent-a1": $accent-a1,
  "accent-a2": $accent-a2,
  "accent-a3": $accent-a3,
  "accent-a4": $accent-a4,
  "accent-a5": $accent-a5,
  "accent-a6": $accent-a6,
  "accent-a7": $accent-a7,
  "error-bg": $error-bg,
  "error-r1": $error-r1,
  "error-r2": $error-r2,
  "error-r3": $error-r3,
  "warning-bg": $warning-bg,
  "warning-w1": $warning-w1,
  "warning-w2": $warning-w2,
  "warning-w3": $warning-w3,
  "sucess-bg": $sucess-bg,
  "sucess-s1": $sucess-s1,
  "sucess-s2": $sucess-s2,
  "sucess-s3": $sucess-s3,
  "background-bg1": $background-bg1
);

// // merge our custom theme color with bootstrap theme color
$theme-colors: map-merge($custom-theme-colors, $theme-colors);
$font-sizes: map-merge($custom-font-sizes, $font-sizes);

// import bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

@import "./spinner";
@import "./common";
@import "./buttons";
@import "./modal";
@import "./stepper";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "@fortawesome/fontawesome-free/css/all.css";

@import "./ag-grid-table";

body {
  background-color: $neutral-n2;
}

.form-control::placeholder {
  color: $neutral-n6;
}

h3,
h2,
h1 {
  font-weight: 600;
}

h4,
h5,
h6 {
  font-weight: 500;
}

.tooltip {
  position: absolute;
  --bs-tooltip-padding-x: 10px;
  --bs-tooltip-padding-y: 10px;
  --bs-tooltip-font-size: 12px;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #311e7c;

  .tooltip-inner {
    text-align: start;

    &.text-center {
      text-align: center;
    }
  }

  &.text-start {
    min-width: 300px;
  }
}

.tooltip.ag-grid.in {
  opacity: 1;
}

.tooltip.ag-grid.in .tooltip-inner {
  max-width: 250px !important;
  text-align: left;
}

.pointer {
  cursor: pointer;
}

.relative,
.pos-relative,
.position-relative {
  position: relative;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

.one-line {
  overflow-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  > span {
    vertical-align: middle;
  }

  > .fa-circle {
    font-size: 6px !important;
  }
}

.two-lines {
  overflow-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  > span {
    vertical-align: middle;
  }
}

.clickable {
  cursor: pointer;

  span {
    border-bottom: 1px dashed $neutral-n6;

    &:hover {
      border-bottom: 1px solid $neutral-n6;
    }
  }
}

.gray-wrap {
  background: $neutral-n3;
  border-radius: 6px;
  display: flex;
  font-size: $H11;
  font-weight: 600;
  height: 22px;
  padding: 3px 6px 4px 6px;
  justify-content: center;
  align-items: center;
  width: auto;

  &:hover {
    background: $primary-p2;
    color: $primary-p6;
  }
}

.v-line {
  height: 70%;
  width: 1px;
  background-color: $neutral-n5;
}

.action-cell {
  border-right: none !important;
}

.highcharts-container {
  max-width: 100%;
}

#backend-channel-connect-status {
  text-align: center;
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 99;
  >.alert {
    max-width: fit-content;
    margin: auto;
  }
}

.icon-bug {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url(/img/icons/icon-bug.svg); 
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-bug-red {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url(/img/icons/icon-bug-red.svg); 
  background-size: contain;
  background-repeat: no-repeat;
}

.text-navy {
  color: $sucess-s2;
}

.w-10 {width: 10%}
.w-20 {width: 20%}
.w-25 {width: 25%}
.w-30 {width: 30%}
.w-40 {width: 40%}
.w-50 {width: 50%}
.w-60 {width: 60%}
.w-70 {width: 70%}
.w-80 {width: 80%}
.w-90 {width: 90%}
.w-100 {width: 100%}
