.stepper-container {
  margin-bottom: 50px !important;

  .steps-header {
    display: flex;
    justify-content: center;
    position: relative;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 20px;

    .step-title {
      font-weight: normal;
    }
  }

  .complete::after {
    content: "";
    background: linear-gradient(89.87deg, #7c23ff 0.67%, #cf47ff 101.23%);
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    left: 0;
    width: var(--progress-width, 0%);
    bottom: -2px;
    height: 4px;
  }

  .step {
    display: block;
    font-size: $H12;
    position: relative;
    padding-right: 50px;
    padding-bottom: 8px;

    &:hover {
      .step-number {
        color: $neutral-n1;
        background-color: $primary-p4;
      }
      .step-title{
        text-decoration: underline;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &:after {
      content: "\e924";
      font-family: icomoon;
      position: absolute;
      right: 16px;
      top: 45%;
      transform: translateY(-50%);
      color: $neutral-n6;
    }

    .step-number {
      width: 24px;
      height: 24px;
      border: 1px solid $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      color: $neutral-n8;
      border-radius: 50%;
      font-size: $H12;

    }

    .step-title {
      color: $neutral-n8;
      font-size: $H12;

      &:hover {
        text-decoration: underline;
      }
    }

    &.active {
      .step-number {
        background-color: $primary;
        color: $neutral-n1;
      }

      .step-title {
        color: $primary;
      }
    }

    &.completed {
      .step-number {
        background-color: $primary;
        color: $primary;
        position: relative;

        &::after {
          content: "\e92d";
          font-family: "icomoon";
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 8px;
          color: $neutral-n1;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .step-head {
    display: flex;
    gap: 8px;
    font-weight: 500;
  }

  .steps-body-wrap {
    display: flex;
    height: 60dvh;
    overflow-y: auto;
    overflow-x: hidden;

    .select-text {
      font-size: $H13;
    }

    .icons-holder {
      .icon-favourite {
        color: $primary-p3;

        &.marked {
          color: $accent-a1;
        }
      }
    }
  }

  .step-body {
    flex: 1;

    .w-half {
      width: 50%;
    }
  }
}

.step-buttons {
  background: $neutral-n1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  border-top: 1px solid #e7eaea;

  .icon-arrow-right {
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 5px;
  }

  .button-group {
    .text-button {
      border: 0;

      span {
        display: inline-block;
        vertical-align: inherit;
        margin-right: 3px;
      }
    }
  }
}
