// Modals Related ...
// Necessary for modal popups ...
.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 1;
  }
}

.modal.fade {
  .modal-dialog {
    transform: translate(0, 0);
    margin: 20px auto;
    min-height: calc(100% - 40px);
    display: flex;
    align-items: center;
    max-width: 700px !important;

    &.small-modal {
      max-width: 375px !important;
    }

    &.medium-modal {
      max-width: 545px !important;
    }

    &.max-large-modal {
      max-width: 1200px !important;
    }

    &.large-modal {
      max-width: 950px !important;
      // height: 80vh;
      height: auto;
      // min-height: 600px;

      .modal-content {
        // height: 100%;
        height: auto;
      }

      .modal-body {
        // height: 100%;
        height: auto;
      }

      .modal-body-wrap {
        min-height: 440px;
        // height: calc(100% - 60px);
        height: auto;
        max-height: 75vh;
        overflow-x: hidden;
      }
    }

    &.extra-large-modal {
      max-width: 1200px !important;
      height: auto;

      .modal-content {
        height: auto;
      }

      .modal-body {
        height: auto;
      }

      .modal-body-wrap {
        min-height: 440px;
        height: auto;
        max-height: 75vh;
        overflow-x: hidden;
      }
    }

    &.full-modal {
      max-width: 85vw !important;
      width: 85vw;
      max-height: 90vh;
      height: auto;

      .modal-content {
        height: 100%;

        .modal-body {
          padding-bottom: 0px;
        }
      }
    }
  }

  .modal-header {
    padding: 0;
    margin: 24px 24px 18px 24px;
    flex-wrap: wrap;
    border: 0;
  }

  .modal-title {
    display: flex;
    width: 100%;
    justify-content: space-between;

    h3 {
      flex: 1;
    }

    .btn-close {
      height: 15px;
      width: 15px;
      background-color: $neutral-n3;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      span {
        color: $neutral-n8;
        font-size: 10px;
      }
    }

    .icon-help {
      font-size: $H11;
    }
  }

  .modal-search {
    width: 100%;
    margin-top: 10px;

    form {
      position: relative;
    }

    input {
      height: 32px;
      padding-left: 35px;
      width: 100%;
      border-radius: 25px;
    }

    .icon-search {
      position: absolute;
      top: 10px;
      left: 14px;
    }
  }

  .modal-body {
    padding: 0;
    margin: 0 24px 0 24px;
    
    .form-check {
      .input-check {
        margin-top: 2px;
      }

      label {
        font-size: $H13;
        color: $neutral-n8;
      }
    }

    .note {
      background-color: $neutral-n3;
      border-radius: 8px;
      padding: 6px 12px;
      color: $neutral-n7;
    }

    .sk-spinner {
      width: 150px;
      height: 150px;
    }

    .export-option-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 25px;
      gap: 10px;
    
      input, label {
        cursor: pointer;
      }
    }
  }

  .modal-footer {
    padding: 0;
    margin: 0 24px 15px 24px;

    .button-holder {
      width: 100%;

      .btn {
        font-size: 12px;
        font-weight: 500;
        padding: 8px 12px;

        span {
          margin-right: 4px;
        }
      }
    }
  }

  .modal-body-wrap {
    overflow-y: auto;
    max-height: 400px;
    scrollbar-width: thin;
  }

  .modal-body-content {
    padding: 20px 0;
    border-bottom: 1px solid $neutral-n3;

    &:last-child {
      border: 0;
    }
  }

  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 95%;
  }

  .chips {
    padding: 4px 8px;
    background: $neutral-n3;
    border-radius: 20px;
    display: flex;
    align-items: center;
    width: max-content;
    max-width: 99%;

    .text {
      color: $neutral-n8;
      font-weight: 500;
      margin-right: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 90%;
      width: calc(100% - 10px);
    }

    .select-btn {
      display: flex;
      width: 16px;
      height: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 5px;
      color: $neutral-n1;
      font-size: 8px;
      margin-right: 6px;
      background-color: $primary;

      &.icon-close {
        font-size: 6px;
      }
    }

    .icon-favourite {
      color: #bdb8ce;
      margin-right: 6px;

      &.marked {
        color: $accent-a1;
      }
    }

    &.selected {
      background-color: $primary;

      .text {
        color: $neutral-n1;
      }

      .select-btn {
        background-color: $neutral-n1;
        color: $neutral-n8;
        transform: rotate(45deg);
      }
    }
  }

  .two-col-box {
    ul {
      li {
        display: flex;
        margin-bottom: $H13;
      }
    }

    .left-col {
      width: 180px;
      display: inline-block;
      font-weight: 500;
      color: $neutral-n8;
    }

    .right-col {
      flex: 1;
      display: inline-block;
      color: $neutral-n8;
      padding-left: 10px;
      overflow-wrap: anywhere;

      &.text-sucess,
      .text-sucess {
        color: $sucess-s2;
      }

      &.text-accent,
      .text-accent {
        color: $accent-a6;
      }

      &.text-error,
      .text-error {
        color: $error-r2;
      }

      .space-highlight {
        background-color: $neutral-n5;
        padding: 2px 3px;
      }
    }
  }

  .footer-note {
    color: $sucess-s2;
  }

  .footer-index {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    li {
      max-width: 33%;
      padding-left: 10px;
      position: relative;
      font-size: $H11;
      color: $neutral-n8;
      margin-right: 15px;

      &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $sucess-s2;
        top: 5px;
        display: inline-block;
        position: absolute;
        left: 0;
      }

      &.error {
        &:before {
          background-color: $error-r2;
        }
      }

      &.black {
        &:before {
          background-color: $neutral-n7;
        }
      }

      &.accent-a6 {
        &:before {
          background-color: $accent-a6;
        }
      }
    }
  }

  .phone-number-block {
    font-weight: 500;
  }

  .radio-title {
    font-weight: 500;
    color: $neutral-n8;
    display: block;
    margin-bottom: 8px;
  }

  .form-title {
    font-weight: 500;
    color: $neutral-n8;
  }
}

.modal-tabs-vertical {
  padding: 8px 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -24px;
    right: -24px;
    border-top: 1px solid $neutral-n4;
  }

  .nav-link {
    color: $neutral-n7;
    font-weight: 500;
    padding: 8px 20px 8px 0;

    &.active {
      color: $primary;
    }
  }

  .nav {
    width: 25%;
    border-right: 1px solid $neutral-n4;

    > li {
      display: flex;
      align-items: center;
    }

    .icon {
      width: $H16;
      margin-right: 2px;
      display: inline-block;
    }

    .nav-text {
      // width: calc(100% - 18px);
      display: inline-block;
    }
  }

  .tab-content {
    padding: 8px 25px;
    overflow-y: auto;
    scrollbar-width: thin;
    flex: 1;
    max-height: calc(100vh - 200px);
  }

  .modal-tab-list {
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -25px;
      right: -25px;
      border-bottom: 1px solid $neutral-n4;
    }

    &:last-child {
      &::after {
        border: 0;
      }
    }

    li {
      margin-bottom: 10px;
      font-size: $H13;
      color: $neutral-n8;

      &.title {
        color: $neutral-n5;
        font-size: $H12;
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.modal-tab-table-list {
      > li {
        display: flex;
        color: $neutral-n7;
        font-size: $H12;

        &.title {
          color: $neutral-n8;
        }

        .left-col {
          width: 190px;
          padding-right: 20px;
        }

        .right-col {
          width: calc(100% - 190px);
          padding-left: 10px;
          color: $neutral-n8;
          word-break: break-all;
        }
      }
    }
  }
}

.custom-file-upload {
  position: relative;
  height: 105px;

  .upload-file-content {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $primary;
    font-weight: 500;
    background: #f9f5ff;
    border: 1.2px dashed rgba(124, 35, 255, 0.2);
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .size-text {
    color: #beb9cb;
  }

  .select-text {
    font-size: $H13;
  }

  input {
    height: 105px;
    opacity: 0;
  }
}

.file-download-box {
  border: 1px solid #E7EAEA;
  padding: 13px 15px;
  border-radius: 8px;

  .left-col {
      align-items: center;
      gap: 8px;
      flex: 1;
      max-width: calc(100% - 113px);

      .icon-holder {
          color: $primary-p3;
      }

      .file-name {
          color: $primary-p11;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: calc(100% - 80px);
      }
  }

  .file-size {
      color: $neutral-n6;
  }

  .file-action {
      gap: 6px;
      margin-left: 24px;

      .progress,.delete {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 8px;
          padding: 3px;
          background-color: $neutral-n5;

          &.completed {
              background-color: $sucess-s2;
              color: $neutral-n1;
          }
      }

      .delete {
          background-color: $primary;
          color: $neutral-n1;
      }
      
  }
}