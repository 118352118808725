// color variables which are according to figma design
$blue: #5930fb;
$white: #fff;
$primary: #5930fb;
$red: #e73d3d;
$green: #87dc9f;
$primary-p1: #F7F4FF;
$primary-p2: #F1EEFA;
$primary-p3: #D9D2F5;
$primary-p4: #7A5AFD;
$primary-p5: #5930FB;
$primary-p6: #5930FB;
$primary-p8: #5200CC;
$primary-p9: #2b1680;
$primary-p10: #291577;
$primary-p11: #110736;
$neutral-n1: #ffffff;
$neutral-n2: #F9F9F9;
$neutral-n3: #f1f1f1;
$neutral-n4: #E2E2E2;
$neutral-n5: #DADADA;
$neutral-n6: #A0A1A3;
$neutral-n7: #68696F;
$neutral-n8: #43494E;
$neutral-n9: #28292D;
$neutral-n10: #121315;
$accent-a1: #FFD900;
$accent-a2: #FF8708;
$accent-a3: #E007FF;
$accent-a4: #12E5CF;
$accent-a5: #59C4FF;
$accent-a6: #258CFD;
$accent-a7: #033DAB;
$error-bg: #F9F2F4;
$error-r1: #fa7575;
$error-r2: #e73d3d;
$error-r3: #c50707;
$warning-bg: #FFF7ED;
$warning-w1: #FFC56F;
$warning-w2: #FE9800;
$warning-w3: #BD5B00;
$sucess-bg: #EAFFFA;
$sucess-s1: #58DFBF;
$sucess-s2: #00A473;
$sucess-s3: #007559;
$background-bg1: #1c0c5b;

$primary-gradient: linear-gradient(90deg, $primary-p5 0%, $primary-p8 50%, $accent-a5 100%);

$base-min-width: 320px;

$spacer: 0.625rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    0_1: (
      $spacer * 0.1,
    ),
    0_2: (
      $spacer * 0.2,
    ),
    0_3: (
      $spacer * 0.3,
    ),
    0_4: (
      $spacer * 0.4,
    ),
    0_5: (
      $spacer * 0.5,
    ),
    0_6: (
      $spacer * 0.6,
    ),
    0_7: (
      $spacer * 0.7,
    ),
    0_8: (
      $spacer * 0.8,
    ),
    0_9: (
      $spacer * 0.9,
    ),
    10: $spacer,
    15: (
      $spacer * 1.5,
    ),
    20: (
      $spacer * 2,
    ),
    25: (
      $spacer * 2.5,
    ),
    30: (
      $spacer * 3,
    ),
    35: (
      $spacer * 3.5,
    ),
    40: (
      $spacer * 4,
    ),
    45: (
      $spacer * 4.5,
    ),
    50: (
      $spacer * 5,
    ),
    55: (
      $spacer * 5.5,
    ),
    60: (
      $spacer * 6,
    ),
    65: (
      $spacer * 6.5,
    ),
    70: (
      $spacer * 7,
    ),
    75: (
      $spacer * 7.5,
    ),
    80: (
      $spacer * 8,
    ),
  ),
  $spacers
);

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Typography
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Inter", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text

$font-size-base: 0.75rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 0.875;
$font-size-sm: $font-size-base * 0.6875;

$line-height-base: 1.3 !default;


// custom font size according to design (For Variable)
$H28: 1.75rem;
$H20: 1.25rem;
$H16: 1rem;
$H14: 0.875rem;
$H13: 0.8125rem;
$H12: 0.75rem;
$H11: 0.6875rem;

$headings-font-weight: 600;
$headings-line-height: 1.25;
$headings-color: $neutral-n9;

// heading styles according to the figma design
$h1-font-size: $H28; 
$h2-font-size: $H20; 
$h3-font-size: $H16; 
$h4-font-size: $H14; 
$h5-font-size: $H13;
$h6-font-size: $H12;

// display-headings
$display-font-sizes: (
  1: 3rem,
  2: 2.5rem,
  3: 2rem,
  4: 1.5rem,
  5: 1.25rem,
  6: 0.75rem,
) !default;

$display-font-weight: 600;

// / Forms

// form-label-variables
$form-label-margin-bottom: 0.5rem;
$form-label-font-weight: 500;
$form-label-color: $neutral-n9;

// form-input-variables
$input-padding-y: 0.64rem;
$input-padding-x: 0.625rem;
$input-bg: $neutral-n1;
$input-disabled-color: $neutral-n7;
$input-disabled-bg: $neutral-n3;
$input-color: $neutral-n8;
$input-border-color: $neutral-n4;
$input-border-radius: 0.25rem;
$input-focus-border-color: $neutral-n5;
$input-focus-box-shadow: none;
$input-focus-width: 0;

$input-placeholder-color: $neutral-n5;
$input-height: 2.375rem;
$form-check-input-focus-border: $primary-p3;
$form-check-input-focus-box-shadow: 0;

// Buttons
// scss-docs-start btn-variables
$btn-padding-y: 0.55rem;
$btn-padding-x: 0.625rem;
$btn-font-size: 0.875rem;
$btn-font-size-sm: 0.775rem;
$btn-font-weight: 500;
$btn-disabled-opacity: 0.65 !default;
// Allows for customizing button radius independently from global border radius
$btn-border-radius: 1.56rem;
$btn-border-radius-sm: 1.56rem;

$table-hover-bg-factor: 1;
$table-hover-bg: $neutral-n2;

$border-color: $neutral-n5;

